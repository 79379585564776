
.slider-father{
  height: 130vh;
  width: 98vw;
  @include flex-column-between;
  @include responsive (minmobile){
    height: 100vh;
  }
  
}
  .slider-son{
      height: 100vh;
      width: 90vw;
      @include flex-column-between;
  .slider{
      height: 60%;
      width: 30vw;
    @include flex-row-between;
    background-color: #000000;
    position: relative;
    @include responsive (laptop){
      width: 50vw;
    }
    @include responsive(bigmobile){
      width: 80vw;
      
    }
    
  @include responsive(mobile){
    width: 70vw;
  }
  .right-button
  {
      background-color:transparent;
      position: relative;
      border: none;
      outline: none;
    &::before{
      content: "<";
      left: -4.5vw;
      top: -8vh;
      position: absolute;
      color: #000;
      font-size: 6rem;
      cursor: pointer;
      @include responsive(greatscreen){
        left: -4.5vw;
    }
  @include responsive(bigscreen){
      left: -5vw;
  }
  @include responsive(laptop){
    left: -7vw;
}
  @include responsive(tab){
    left: -7vw;
  }

  @include responsive(bigmobile){
    left: -9vw;
    font-size: 4rem;

}

@include responsive(minmobile){
    left: -8.5vw;
    font-size: 3rem;
}

  &:hover{
    transform: scale(1.1);
        }
        }
    }

.left-button
{
   background-color:transparent;
   border:none;
   outline: none;
   position: relative;
 &::before{
   content: ">";
   top: -8vh;
   color: #000;
   position: absolute;
   font-size: 6rem;
   left: 2vw;
   cursor: pointer;
   @include responsive(greatscreen){
    left: 1vw;
}
   @include responsive(laptop){
    left: 0.5vw;
  }
  @include responsive(mobile){
    left: -8vw;
  }
  @include responsive(bigmobile){
    left: 1vw;
    font-size: 4rem;
  }
  @include responsive(minmobile){
    font-size: 3rem;
  }

&:hover{
 transform: scale(1.1);
}   
 }
 }
 .overlay-father{
    width: 30vw;
    height: 35vh;
    position: relative;
    @include responsive(laptop){
       width: 50vw;
    }
    @include responsive(bigmobile){
       width: 80vw;

    }
    @include responsive(mobile){
       width: 70vw;
    }
    
.overlay {
  position: absolute;
 width: 100%;
 height: 35vh;
  background-color: rgba(0 , 0, 0, 0.05);
@include flex-column-start-center;
 color: #ffffff;
    top: -4vh;
    opacity: 0;
&:hover {
  opacity: 1;
  cursor: pointer;
}


  .desc{
  font-size: 1.5rem;
  font-weight: bolder;
  position: relative;
  @include responsive(minmobile){
    font-size: 1rem;
}}
  
  @include responsive(tab){
    top: -5vh;
  }
    @include responsive(minmobile){
        font-size: 10px;
  }
}
.img{
  width: 100%;
  position: absolute;
  height: 30vh;

  @include responsive(laptop){
     width: 50vw;
  }
  @include responsive(bigmobile){
     width: 80vw;
  }
  @include responsive(mobile){
    width: 70vw;
  }
}
  }
}
  }