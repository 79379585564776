@mixin flex-row-start{
    display: flex;
    justify-content:center;
    align-items: flex-start;
    flex-direction: row;
}
@mixin flex-row-start-center{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}
@mixin flex-row-end{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
}
@mixin flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row
}
@mixin flex-row-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row
}
@mixin flex-row-evently {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row
}
@mixin flex-column-start{
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    flex-direction: column;
}
@mixin flex-column-center-evently{
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    flex-direction: column;
}
@mixin flex-column-start-center{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
}
@mixin flex-column-end{
    display: flex;
    justify-content:flex-end;
    align-items: center;
    flex-direction: column;
}
@mixin flex-column-end-end{
    display: flex;
    justify-content: end;
    align-items: flex-end;
    flex-direction: column;
}
@mixin flex-column-center{
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}
@mixin flex-column-start-between{
    display: flex;
    justify-content:flex-start;
    align-items:space-between;
    flex-direction: column;
}

@mixin flex-column-between{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}
@mixin flex-column-start-evently{
    display: flex;
    justify-content: space-evenly;
    align-items:flex-start;
    flex-direction: column;
}


@mixin flex-column-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@mixin  responsive ($breakpoint) {
    @if $breakpoint == superGreatScreen {
        @media only screen and (max-width: 1815px){@content};
        }

    @if $breakpoint == greatscreen {
        @media only screen and (max-width: 1500px){@content};
        }
    
    @if $breakpoint == bigscreen {
        @media only screen and (max-width: 1224px){@content};
        }
        
    @if $breakpoint == laptop {
        @media only screen and (max-width: 1024px){@content};
        }

    @if $breakpoint == tab {
        @media only screen and (max-width: 800px) {@content};
        }
        @if $breakpoint == bigmobile {
            @media only screen and (max-width: 660px) {@content};
            }

    @if $breakpoint == mobile  {
        @media only screen and (max-width: 500px) {@content};
        }
    @if $breakpoint == minmobile {
        @media only screen and (max-width: 350px) {@content};
        } 
}
