.contact-father{
    height: 100vh;
    width: 98vw;
    @include flex-column-center;
    
    form{
    @include flex-column-between;
    max-width: 100%;
    height: auto;
    margin-top: 2vh;
   border-radius: 10px;
   background-color: #f2f2f2;
    }
    .name-email-father{
        width: 100%;
        height: 20vh;
        @include flex-column-between;
    }

    input[type="text"], input[type="email"] 
    {
    border: none;
    outline: none;
    color: black;
    background-color: transparent;
    border-bottom: 1px solid red;
    box-sizing: border-box;
    height: 4vh;
    width: 100%;
    
    
    font: 1.5rem 'Times New Roman', Times, serif;
    text-align: center;
    @include responsive(minmobile){
    
    
    }
    &::placeholder{
        font: 1.5rem 'Times New Roman', Times, serif;
    }
}
.send {
    margin-top: 2vh;
height: auto;
width: 100%;
background-color: rgb(0, 174, 255);
font: 2rem 'Times New Roman', Times, serif;
text-align: center;

}
textarea{
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.5rem;
    width: 40vw;
    height: 44vh;
    border: none;
    &::placeholder{
        font: 1.5rem 'Times New Roman', Times, serif;
        text-align: center;
    }
    @include responsive(tab){
        width: 79vw;
    }
}
}