.father-load{
  @include flex-column-center;
  height: 100vh;
  width: 100vw;

.load-word{
  color: #ffffff;
  margin-top: 1vh;
}
  .load{
      width: 100px;
      height: 100px;
      border: 20px solid orange;
      border-radius: 50% ;
      border-top: 10px solid transparent ;
      animation: load 200ms infinite linear;
      position: relative;
    }  
  }
    @keyframes load{
      from{
        transform: rotate(0);
      }
      to{
        transform: rotate(360deg);
      }
    }  
      
      