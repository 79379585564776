.skills-title-father, .skills-father-title-great-screen{
    height: 22vh;
    width: auto;
    @include flex-column-start;
    align-self: flex-start;
    padding-left: 2vw ;

}
.skills-father-title-great-screen{
    @include responsive(bigmobile){
        @include  flex-column-end-end;
    }
}
.skills-title-father{
    @include responsive(bigmobile){
        animation: leftTitle 3s ease-in-out;  
        @include  flex-column-end-end;
    }
}
.skill-father{
    width: 98vw;
    height: 177vh;
    @include flex-column-between;
        @include responsive(bigmobile){
            height: 250vh;   
        }
        @include responsive(minmobile){
            height: 300vh;
        }
       
    .skills-title{
        height: 2vh;
        font-size: 2rem;
        font-weight: bolder;
    }

.skill-son{
    width: 97vw;
    height: 90vh;
    @include flex-row-evently;
    @include responsive(bigmobile){
        @include flex-column-between;
        height: 250vh;
        }
    
        
    .img-father {
        width: 20vw;
        @include flex-row-evently;
        @include responsive(greatscreen){
            width: 23vw;
        }
        @include responsive(bigscreen){
            width: 26vw;
        }
    
        @include responsive(bigmobile){
            width: 45vw;

        }
        @include responsive(mobile){
            width: 65vw;
        }
        @include responsive(minmobile){
            width: 85vw;
        }

        .img-wrapper{
            width: 3vw;
            height: 7vh;
            @include flex-column-start-evently;
            @include responsive(laptop){
                width: 5vw;

            }
            @include responsive(mobile){
                width: 7vw;

            }
            @include responsive(minmobile){
                width: 9vw;

            }
            .img-skills{
                height: auto;
                width: 200%;
                max-width: 100%;
                float: left;
            }
         
        }
        h2{
            @include flex-row-center;
        }
    }
        
.program, .lang, .others, .scrollSkills, .scrollLang, .scrollOthers
{
    @include flex-column-between;
    height: auto;
    width:20vw;
    background-color: rgb(128, 126, 126);
    color: white;
    line-height: 6vh;
    @include responsive(greatscreen){
        width: 23vw;
    }
    @include responsive(bigscreen){
        width: 26vw;
    }
    @include responsive(laptop){
        width: 29vw;
    }
    
    @include responsive(bigmobile){
        width: 45vw;
    }
    @include responsive(mobile){
        width: 65vw;
    }
    @include responsive(minmobile){
        width: 85vw;
    }
                    .title-father{
                        width: 15vw;
                        @include flex-column-center;
                        @include responsive(greatscreen){
                            width: 18vw;
                        }
                        @include responsive(bigscreen){
                            width: 21vw;
                        }
                        @include responsive(laptop){
                            width: 25vw;
                        }
                        @include responsive(bigmobile){
                            width: 35vw;
                        }
                        @include responsive(mobile){
                            width: 50vw;
                        }
                        @include responsive(minmobile){
                            width: 65vw;
                        }  
                    }
            
    .title{
        font: 1.3rem 'Times New Roman', Times, serif;
        @include responsive(tab){
            font-size: 1rem;
        }
    }
.h-skills{
    background-color:purple;
    width: 100%;
    text-align: center;
    font: 2rem'Times New Roman', Times, serif;
    @include responsive(tab){
        font-size: 1.8rem;
        }
}
}
.program {
    margin-top: 4vh;
    animation: left 3s ease-in-out;
    
}
.scrollSkills{
    margin-top: 4vh;
    animation: left1 3s ease-in-out;
}
.scrollLang , .lang{
    animation: down 3s ease-in-out;
}
.scrollOthers , .others{
    animation: right 3s ease-in-out;
}
}
}

@keyframes left  {
    0%{
        opacity: 0;
    }
    25%{
        opacity: 0;
        position: relative;
        left: -10vw;
    }
    
    75%{
        transition: 100ms ease-in-out;
        display: block;
        position: relative;
        left: 0;
    }
}

@keyframes leftTitle  {
    0%{
        opacity: 0;
    }
    75%{
     opacity: 0;
        position: relative;
        left: -10vw;
    }
    
    95%{
        transition: 100ms ease-in-out;
        display: flex;
        position: relative;
        left: 0;
    }
}


@keyframes left1  {
    0%{
        opacity: 0;
    }
    25%{
        opacity: 0;
        position: relative;
        left: -10vw;
    }
    
    75%{
        transition: 100ms ease-in-out;
        display: block;
        position: relative;
        left: 0;
    }
}

@keyframes right  {
    0%{
        display: none;
    }
    25%{
        display: none;
        position: relative;
        right: -10vw;
    }
    
    75%{
        transition: 100ms ease-in-out;
        display: block;
        position: relative;
        right: 0;
    }
}

@keyframes down  {
    0%{
        display: none;
    }
    25%{
        display: none;
        position: relative;
        top: -33vw;
    }
    75%{
        transition: 100ms ease-in-out;
        display: block;
        position: relative;
        top: 0;
    }
}