.aboutme-title-father{
    height: 8vh;
    width: auto;
    padding-left: 4vw;
    @include flex-column-end;
    align-self: flex-start;
 
  
}
.aboutme-title{
    font-size: 2rem;
    font-weight: bolder;
    @include flex-row-center;
}
    
.about-father{
    @include flex-column-between;
    max-width: 98vw;
    height: auto;
    @include responsive(laptop){
        height: 250vh;
        
    }
    @include responsive(tab){
        height: 400vh;
    }
    @include responsive(bigmobile){
        height: 300vh;
    }
    @include responsive(mobile){
        height: 500vh;
    }
    @include responsive(minmobile){
        height: 600vh;
    }
        .header-about-father{
            height: 22vh;
            max-height: auto;
            max-width: 90vw;
            @include flex-column-center;
            @include responsive(mobile){
                height: 4vh;
            }
        }
        
    .header-about{
        font-family: verdana;
        font-size: 6em;
        font-weight: 700;
        color: #f5f5f5;
        text-align: center;
        text-shadow: 1px 1px 1px gainsboro , 1px 2px 1px gainsboro, 1px 3px 1px gainsboro , 
        1px 4px 1px gainsboro, 1px 5px 1px gainsboro,  1px 6px 1px gainsboro, 
        1px 7px 1px gainsboro, 1px 8px 1px gainsboro, 1px 9px 1px gainsboro, 1px 10px 1px gainsboro ,
        1px 18px 6px rgba(16 , 16 , 16 , 0.4),  1px 22px 10px  rgba(16 , 16 , 16 , 0.2), 
        1px 25px 35px  rgba(16 , 16 , 16 , 0.2),  1px 30px 60px  rgba(16 , 16 , 16 , 0.4);
    }
    @include responsive(mobile){
        font-size: 10px;
    }

.about-text{
    font: 2rem 'Times New Roman', Times, serif;
    width: 50%;
    padding: 0 15px;
    // text-indent: 4px;
    height: auto;
    line-height: 4rem;
    background-color: black;
    color: white;
    white-space:pre-line;
    @include responsive (bigmobile){
font-size: 1rem;
    }
    @include responsive(superGreatScreen){
        width: 90vw;
    }
    
}
p::first-letter{
    color: red;
    font-size: 4rem;
    text-indent: 5cm;
}  
}


// /* Mini Mobile (280px - 350px) */
// @media (min-width: 280px) and (max-width: 350px) {
//     .responsive-element {
//       height: auto;
//       min-height: 158px;
//       max-height: 197px;
//     }
//   }
  
//   /* Mobile (350px - 500px) */
//   @media (min-width: 350px) and (max-width: 500px) {
//     .responsive-element {
//       height: auto;
//       min-height: 197px;
//       max-height: 281px;
//     }
//   }
  
//   /* Big Mobile (500px - 650px) */
//   @media (min-width: 500px) and (max-width: 650px) {
//     .responsive-element {
//       height: auto;
//       min-height: 281px;
//       max-height: 366px;
//     }
//   }
  
//   /* Tablet (650px - 800px) */
//   @media (min-width: 650px) and (max-width: 800px) {
//     .responsive-element {
//       height: auto;
//       min-height: 488px;
//       max-height: 600px;
//     }
//   }
  
//   /* Laptop (800px - 1024px) */
//   @media (min-width: 800px) and (max-width: 1024px) {
//     .responsive-element {
//       height: auto;
//       min-height: 450px;
//       max-height: 576px;
//     }
//   }
  
//   /* Screen (1024px - 1200px) */
//   @media (min-width: 1024px) and (max-width: 1200px) {
//     .responsive-element {
//       height: auto;
//       min-height: 576px;
//       max-height: 675px;
//     }
//   }
  
//   /* Big Screen (1200px - 1500px) */
//   @media (min-width: 1200px) and (max-width: 1500px) {
//     .responsive-element {
//       height: auto;
//       min-height: 675px;
//       max-height: 844px;
//     }
//   }
  
//   /* Huge Screen (1550px - 1800px) */
//   @media (min-width: 1550px) and (max-width: 1800px) {
//     .responsive-element {
//       height: auto;
//       min-height: 873px;
//       max-height: 1013px;
//     }
//   }
  