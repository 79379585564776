.footer{
    height: 16vh;
    width: 100vw;
    @include flex-row-between;    
.footer-father{
    align-self: flex-end;
    height: 16vh;
    width: 100%;
    background-color:black;
    @include flex-row-evently;
    .reference{
        width: 30%;
        height: auto;
        color:white;
        padding-left: 0.1vw;
        @include flex-row-start-center; 
        @include responsive(laptop){
            padding-left: 2vw;
        }
        @include responsive(bigmobile){  
            width: 20%;
            padding-left: 2vh
            }
            @include responsive(mobile){
             @include flex-row-center;
            }

        .reference-an{
            width: 8vw;
            font-size: 1rem;
            text-align: center;
            @include flex-column-center;
            @include responsive(minmobile){
                font-size: 0.8rem;
            }
        }
        .heart{
            color:red;
            animation: heart 1s infinite ease-in-out;
            height: 4vh;
            width: 4vw;
        
        }
    }
}
    .reference-mid-father{
        width: 51vw;
        @include flex-row-center;
        @include responsive(bigmobile){
            width: 70vw;
            
        }
        @include responsive(mobile){
        }
        .reference-mid{
            color: #ffffff;
            max-width: 80%;  
            text-align: right;
            @include flex-row-center;
            @include responsive(mobile){
                font-size: 1rem;
            
            } 
            @include responsive(minmobile){
                font-size: 0.8rem;
            }
        }
        .date{
            max-width: 20%;   
            text-align: left;  
            .date-input{
                background-color: transparent;
            border: none;
            outline: none;
            color: white;
            }
        }
    
}
.icon-father{
    width: 20%;
    @include flex-row-evently;
        @include responsive(bigmobile){
            width: 10%;
            @include flex-column-start-center;
            }
.icon{
    color: white;
    font-size: 1rem;
    list-style: none;
    text-decoration: none;
}
}
@keyframes heart{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(0.5);
    }
}
}