
.mobileNav{
    display: none
}
.a-logo, .n-logo{
    color: white;
    font-size: 4rem;
    position: relative;
    @include responsive(tab){
        font-size: 3rem;
    }
    @include responsive(bigmobile){
    font-size: 2.5rem;
    }
}
.a-logo{
    text-shadow: 5px 5px 1px  black ;
}
.n-logo{
    position: relative;
    left: -4vh;
    color: black;
    text-shadow: 5px 5px 1px white ;
    transform: rotate(44deg);
    @include responsive(tab){
        left: -3vw;
    }
}

nav .active{
    border-bottom: 4px solid #000;
    padding-bottom: 2vh ;
}

.head{
    height: 12vh;
    max-width: 100%;
    background-color: lightgray;
    position: relative;
            @include flex-row-center;
            @include responsive(bigmobile){
                @include flex-row-start;
        
            }
       
            &:before{
                position: absolute;
                content: "";
                top: 33%;
                right: 50%;
                left: 20%;
                bottom: 0;
                border-radius: 50%;
                height: 12vh;
                width: 66vw;
                background-color: lightgray;   
            }
        }
        nav{
            width: 100%;
    @include flex-row-center;
    height: 4vh;
    z-index: 0;
    list-style: none;
        }
        ul{
            width: 100%;
            @include responsive(bigmobile){
                @include flex-row-end;
                height: 11vh;
            }
        
        }
        
        li {
            @include flex-row-evently;
            width: 100%;
            cursor: pointer;
            font:  1.5rem 'Rowdies', Times, serif;
        }
    
    
        .anchor{
        text-decoration: none;
        color: #000;
        font:  1.5rem 'Rowdies', Times, serif;
        @include responsive(laptop){
            font-size: 1.2rem;
        }
        @include responsive(tab){
            font-size: 1rem;
        }
        @include responsive(tab){
            font-size: 0.8rem;
        }

        }
        
        
@media (max-width: 500px){
    
    .desktopHeader{
        display: none;
    }
    .mobileNav{
        display: flex;
    }
    .hamburgerMenu{
        color: white;
        font-size: 2rem;;

    }
    .head{
        width: auto;
        margin-left: 2vw;
        height: auto;
        @include flex-column-center-evently; 
        &::before{
            display: none;
        }
    }
        .a-logo, .n-logo{
            font-size: 2rem;
            
            
    }
    nav{   
        height: auto;
    }
    ul{
        height: auto;
         @include flex-column-end-end;
    }
    li{     
        @include flex-column-center-evently;   
        height: 22vh;
        width: auto;
        margin: 0 2vw;
        
    }
    nav .active{
        padding-bottom: 1px ;
        
    }
}

